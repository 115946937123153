<template>
  <div>
    <p class="text-2xl mb-6">Locatario/Area</p>
    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <puesto-nuevo @CargarListaPuesto="CargarListaPuesto" :PuestoDatos="puesto"></puesto-nuevo>
        </v-col>
        <v-col lg="12" cols="12">
          <puesto-lista ref="PuestoListaRef" @GetEditarPuesto="GetEditarPuesto"></puesto-lista>
        </v-col>
      </v-row>
     
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CropImagen from '@/components/CropImagen.vue';
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos 
import { onBeforeMount, ref } from '@vue/composition-api';
import PuestoLista from './components/PuestoLista.vue';
import PuestoNuevo from './components/PuestoNuevo.vue';
import config from '@/api/config';
export default
  {
    components: { CropImagen, PuestoLista, PuestoNuevo },
    setup() {
      const puesto = ref({
        id: 0,
        nombre: "",
        descripcion: "",
        encargado: "",
        id_grupo_negocio: 1,
        puesto_tipo: "",
        comision: "100",
        telefono: "",
        telefono2: "",
        img: null,
        ind_venta: true,
        ind_interno: false,
        
      })
      const PuestoListaRef = ref(null)

      const CargarListaPuesto = () => {
     
        PuestoListaRef.value.cargarPuestos()
      }
      const GetEditarPuesto = data => {
        
        console.log(data)
        puesto.value = {
          id: data.id,
          nombre: data.nombre,
          descripcion: data.descripcion,
          encargado: data.encargado,
          id_grupo_negocio: data.grupo_negocio_json.id ,
          puesto_tipo: data.puesto_tipo,
          comision: data.comision,
          telefono: data.telefono,
          telefono2: data.telefono2,
          img: data.img,
          ind_venta: data.ind_venta,
          ind_interno: data.ind_interno,
        }
      }

      return {
        GetEditarPuesto,
        CargarListaPuesto,
        puesto,
        PuestoListaRef
      }
    },
  }
</script>
