var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Lista Puestos "),_c('v-btn',{attrs:{"icon":"","color":"green","loading":_vm.cargandoDatos,"disabled":_vm.cargandoDatos},on:{"click":function($event){return _vm.cargarPuestos()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCached))])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.usuarioList,"search":_vm.search,"items-per-page":5,"loading":_vm.usuarioListLoad},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"1.875rem","color":"primary"}},[(item.img)?_c('v-img',{attrs:{"src":((_vm.config.api_imagenes) + "/puesto/img/s-" + (item.img))}}):_c('span',[_vm._v(_vm._s(item.nombre.slice(0, 2).toUpperCase()))])],1)],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.nombre)+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.grupo_negocio_json.descripcion))])])])])]}},{key:"item.encargado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.encargado))]),_c('br'),_c('small',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.telefono))])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.puesto_tipo_json.descripcion))])]}},{key:"item.ind",fn:function(ref){
var item = ref.item;
return [(item.ind_interno)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":item.ind_interno ? 'success' : 'secondary'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.ind_interno ? _vm.icons.mdiCheck : _vm.icons.mdiCancel)+" ")]),_vm._v(" Interno ")],1):_vm._e(),(item.ind_venta)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":item.ind_venta ? 'primary' : 'secondary'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.ind_venta ? _vm.icons.mdiCheck : _vm.icons.mdiCancel)+" ")]),_vm._v(" Ventas ")],1):_vm._e(),(item.estado !== 5)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":'error'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")]),_vm._v(" Inactivo ")],1):_vm._e()]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetEditarPuesto(item)}}},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2"},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.estado == 5 ? _vm.icons.mdiCancel : _vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.estado == 5 ? 'Inctivar' : 'Activar'))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }