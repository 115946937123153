<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col lg="6" md="6" cols="12">
            <v-text-field
              :rules="reglas.nombre"
              v-model="datos.nombre"
              label="Nombre *"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6 mt-6"
            ></v-text-field>

            <v-textarea
              class="mb-6"
              v-model="datos.descripcion"
              label="Descripción *"
              required
              outlined
              :rules="reglas.descripcion"
              name="input-7-4"
              hide-details="auto"
            ></v-textarea>

            <v-text-field
              :rules="reglas.encargado"
              v-model="datos.encargado"
              label="Nombre Encargado *"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="datos.id_grupo_negocio"
              :items="gruposNegocios"
              item-text="descripcion"
              item-value="id"
              :rules="reglas.grupo_negocio"
              label="Grupo administracion*"
              required
              hide-details="auto"
              class="mb-6"
              outlined
              dense
            ></v-select>

            <v-select
              v-model="datos.puesto_tipo"
              :items="puestoTipos"
              item-text="descripcion"
              item-value="id"
              :rules="reglas.puesto_tipo"
              label="Tipo *"
              required
              hide-details="auto"
              class="mb-6"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col lg="6" md="6" cols="12">
            <crop-imagen
              ref="refImagen"
              :requerido="true"
              :rounded="false"
              :urlImgDefault="urlImgDefault"
              :urlImgValue="urlImgValue"
            >
            </crop-imagen>
            <v-text-field-percent
              class="mb-6"
              v-model="datos.comision"
              v-bind:label="'Participación %'"
              v-bind:properties="{
                suffix: '%',
                readonly: false,
                disabled: false,
                outlined: false,
                placeholder: '',
                rules: reglas.comision,
                outlined: true,
                dense: true,
              }"
              v-bind:options="{
                locale: 'es-VE',
                length: 3,
                precision: 2,
                empty: null,
              }"
            />

            <v-text-field-simplemask
              v-model="datos.telefono"
              v-bind:label="'Telefono'"
              v-bind:properties="{
                outlined: true,
                clearable: false,
                dense: true,
                rules: reglas.telefono,
              }"
              v-bind:options="{
                inputMask: '(####) ### ## ##',
                outputMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: true,
              }"
            />

            <v-text-field-simplemask
              v-model="datos.telefono2"
              v-bind:label="'Telefono 2'"
              v-bind:properties="{
                outlined: true,
                clearable: false,
                dense: true,
                rules: reglas.telefono,
              }"
              v-bind:options="{
                inputMask: '(####) ### ## ##',
                outputMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: true,
              }"
            />

            <v-row>
              <v-col>
                <v-switch v-model="datos.ind_venta" :label="`Ind Venta `"></v-switch>
              </v-col>
              <v-col>
                <v-switch v-model="datos.ind_interno" :label="`Ind Interno `"></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-btn v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" @click="GuardarPuesto()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import PuestoService from '@/api/servicios/PuestoService'
import store from '@/store'
import config from '@/api/config'
export default {
  components: { CropImagen },
  props: {
    PuestoDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.PuestoDatos)))
    const puestoTipos = ref([])
    const gruposNegocios = ref([])
    const refImagen = ref(null)
    const form = ref()
    const urlImgDefault = ref(require('@/assets/images/3d-img/store.jpg'))
    const urlImgValue = ref(config.api_imagenes2 + 'puesto/img/' + datos.img)
    watch(props, () => {
      resetear()
      console.log(datos.value.img)
    })

    onBeforeMount(() => {
      PuestoTipoListar()
      GrupoNegocioListar()
    })

    const reglas = {
      descripcion: [
        v => !!v || 'descripcion es requerido',
        v => (v && v.length >= 3) || 'Debe de asignar al menos una descripción cortaz',
      ],
      id: -1,
      img: '',
      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 20 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      comision: [v => !!v || 'Participacion es requerido'],
      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'Participacion Invalida, Ej: 15 o 12.5'
            : null,
      ],
      telefono2: '',
      puesto_tipo: [v => !!v || 'Tipo es requerido'],
      grupo_negocio: [v => !!v || 'Tipo es requerido'],
      encargado: [v => !!v || 'Nombre encargado es requerido'],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const PuestoTipoListar = () => {
      PuestoService.obtenerPuestosTipo()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            puestoTipos.value = response.data.datos
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'PuestoTipoListar-puestoNuevo',
          })
        })
        .finally(() => {})
    }
    const GrupoNegocioListar = () => {
      PuestoService.obtenergrupoNegocio()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            gruposNegocios.value = response.data.datos
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',

            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'GrupoNegocioListar-puestoNuevo',
          })
        })
        .finally(() => {})
    }
    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.PuestoDatos))
      urlImgValue.value = config.api_imagenes2 + 'puesto/img/' + datos.value.img + '?' + Math.random()

      form.value.resetValidation()
    }
    const limpiar = () => {
      datos.value = {
        id: 0,
        nombre: '',
        descripcion: '',
        encargado: '',
        id_grupo_negocio: 1,
        puesto_tipo: '',
        comision: '100',
        telefono: '',
        telefono2: '',
        img: null,
        ind_venta: true,
        ind_interno: false,
      }
      urlImgValue.value = null
      form.value.resetValidation()
    }
    const GuardarPuesto = async () => {
      try {
        console.log(refImagen.value.datos.imgInput)
        if (validarForm() && refImagen.value.ImgValidado) {
          let imagen = ''

          if (refImagen.value.datos.imgInput) {
            const form = new FormData()
            form.append('file', refImagen.value.datos.imgInput, 'img.jpg')
            //SUBIR IMAGEN
            console.log('asq')

            await PuestoService.subirImgPuesto(form)
              .then(response => {
                console.log(response)
                imagen = response.data.img
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                console.log('Termino')
              })
          }
          //CREAR O MODIFICAR
          await PuestoService.modificarPuesto({
            ...datos.value,
            img: imagen == '' ? datos.value.img : imagen,
          })
            .then(response => {
              console.log(response)

              limpiar()
              context.emit('CargarListaPuesto')
              store.commit('setAlert', {
                message: 'Guardado con exito',
                type: 'success',
              })
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: 'Disculpe se produjo un error al momento de guardar el empleado : ' + error,
                type: 'error',
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'GuardarPuesto-puestoNuevo',
              })
            })
            .finally(() => {
              console.log('Termino')
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',

          error: error,
          funcion: 'GuardarPuesto-2-puestoNuevo',
        })
      }
    }

    return {
      valid,
      datos,
      reglas,
      puestoTipos,
      refImagen,
      GuardarPuesto,
      form,
      gruposNegocios,
      limpiar,
      config,
      resetear,
      urlImgDefault,
      urlImgValue,
    }
  },
}
</script>