
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista Puestos

        <v-btn icon @click="cargarPuestos()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="usuarioList"
        :search="search"
        :items-per-page="5"
        :loading="usuarioListLoad"
        class="table-kitchen-sink"
      >
        <!-- product -->
        <template #[`item.nombre`]="{ item }">
          <div class="d-flex align-center">
            <div>
              <v-avatar size="1.875rem" color="primary" class="v-avatar-light-bg primary--text">
                <v-img v-if="item.img" :src="`${config.api_imagenes}/puesto/img/s-${item.img}`"></v-img>
                <span v-else>{{ item.nombre.slice(0, 2).toUpperCase() }}</span>
              </v-avatar>
            </div>
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text-truncate text--primary"
                >{{ item.nombre }}
                <br />
                <small>{{ item.grupo_negocio_json.descripcion }}</small>
              </span>
            </div>
          </div>
        </template>
        <template #[`item.encargado`]="{ item }">
          <span class="text-no-wrap">{{ item.encargado }}</span>
          <br />
          <small class="text-no-wrap">{{ item.telefono }}</small>
        </template>
        <template #[`item.tipo`]="{ item }">
          <span class="text-no-wrap">{{ item.puesto_tipo_json.descripcion }}</span>
        </template>
        <template #[`item.ind`]="{ item }">
          <v-chip v-if="item.ind_interno" class="ma-2" x-small :color="item.ind_interno ? 'success' : 'secondary'">
            <v-icon left>
              {{ item.ind_interno ? icons.mdiCheck : icons.mdiCancel }}
            </v-icon>
            Interno
          </v-chip>

          <v-chip v-if="item.ind_venta" class="ma-2" x-small :color="item.ind_venta ? 'primary' : 'secondary'">
            <v-icon left>
              {{ item.ind_venta ? icons.mdiCheck : icons.mdiCancel }}
            </v-icon>
            Ventas
          </v-chip>
          <v-chip v-if="item.estado !== 5" class="ma-2" x-small :color="'error'">
            <v-icon left>
              {{ icons.mdiCancel }}
            </v-icon>
            Inactivo
          </v-chip>
        </template>
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditarPuesto(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on">
                <v-icon>{{ item.estado == 5 ? icons.mdiCancel : icons.mdiCheck }}</v-icon>
              </a>
            </template>
            <span>{{ item.estado == 5 ? 'Inctivar' : 'Activar' }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
} from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import PuestoService from '@/api/servicios/PuestoService'
import config from '@/api/config'
import store from '@/store'
export default {
  setup(props, context) {
    const search = ref('')
    const usuarioList = ref([])
    const usuarioListLoad = ref(false)
    const cargandoDatos = ref(false)
    const GetEditarPuesto = item => {
      console.log(item)
      context.emit('GetEditarPuesto', { ...item })
      goTo(0)
    }
    onBeforeMount(() => {
      cargarPuestos()
    })

    const cargarPuestos = () => {
      try {
        cargandoDatos.value = true
        PuestoService.obtenerPuestos2()
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              usuarioList.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',

          error: error,
          funcion: 'cargarPuestos-puestoListar',
        })
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
      },
      search,
      headers: [
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'DESCRIPCION', value: 'descripcion' },
        { text: 'ENCARGADO', value: 'encargado' },
        { text: 'TIPO', value: 'tipo' },
        { text: 'IND', value: 'ind' },
        { text: 'ACCION', value: 'accion', sortable: false },
      ],
      usuarioList,
      usuarioListLoad,
      GetEditarPuesto,
      cargarPuestos,
      config,
      cargandoDatos,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  